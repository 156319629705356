<template>
  <v-card>
    <v-card-title>Intervalos de tempo para pagamento</v-card-title>

    <v-data-table
      :headers="headers"
      :items="intervals"
      disable-pagination
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            small
            color="success"
            @click="$router.push('/intervalos-de-pagamento/cadastrar')"
            >Cadastrar +</v-btn
          >
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          title="Editar intervalo"
          @click="editInterval(item.id)"
          small
          icon
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          title="Deletar intervalo"
          @click="deleteInterval(item.id)"
          small
          icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>



<script>
import errorHandler from "@/helpers/error_handler";
export default {
  data: () => ({
    headers: [
      { text: "#ID", value: "id" },
      { text: "Nome", value: "name" },
      { text: "Ações", value: "actions" },
    ],
    loading: false,
    intervals: [],
    resultsPerPage: 15,
    totalRows: 0,
    filters: {
      currentPage: 1,
    },
  }),
  methods: {
    async loadintervals() {
      try {
        this.loading = true;

        let url = `/time-intervals`;

        const response = await this.$axios.get(url);

        this.setintervals(response.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    setintervals(data) {
      const cpData = [...data];
      this.intervals = cpData;
    },
    async deleteInterval(id) {
      try {
        const confirm = await this.$root.$confirm(
          "Atenção",
          `Deseja realmente deletar a o intervalo de pagamento com o id n. ${id} ?`
        );

        if (!confirm) return;

        this.loading = true;

        let url = `/time-intervals/${id}`;

        await this.$axios.delete(url);

        this.deleteIntervalFromTable(id);

        this.$toast.success("Dados deletados com sucesso");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    deleteIntervalFromTable(id) {
      const index = this.intervals.findIndex((p) => p.id === id);

      this.intervals.splice(index, 1);
    },
    editInterval(id) {
      this.$router.push(`/intervalos-de-pagamento/${id}`);
    },
  },
  computed: {},
  created() {
    this.loadintervals();
  },
};
</script>

<style>
</style>